@charset "utf-8";

@import "./theme_dark.scss";

@import "./wip_indicator.scss";

@import 'bulma/bulma.sass';


.main {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.page {
    margin: 1rem 0;
    min-height: 82vh;
}

.is-fullwidth {
    width: 95%;
}