// Colors
$black: hsl(14, 14%, 14%);
$black-bis: hsl(14, 14%, 26%);
$black-ter: hsl(14, 14%, 36%);

$grey-darker: hsl(0, 0%, 21%);
$grey-dark: hsl(0, 0%, 29%);
$grey: hsl(0, 0%, 48%);
$grey-light: hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);
$grey-lightest: hsl(0, 0%, 93%);

$white-ter: hsl(0, 0%, 96%);
$white-bis: hsl(0, 0%, 98%);
$white: hsl(0, 0%, 100%);

$dark: hsl(14, 14%, 14%);
$dark-bis: hsl(14, 14%, 26%);
$dark-ter: hsl(14, 14%, 36%);

$mid-darker: hsl(55, 31%, 30%);
$mid-dark: hsl(55, 31%, 41%);
$mid: hsl(55, 31%, 50%);
$mid-light: hsl(55, 31%, 60%);
$mid-lighter: hsl(55, 31%, 86%);
$mid-lightest: hsl(55, 31%, 93%);

$light-ter: hsl(174, 31%, 91%);
$light-bis: hsl(174, 31%, 95%);
$light: hsl(174, 31%, 98%);

$orange: hsl(14, 100%, 53%);
$yellow: hsl(48, 100%, 67%);
$green: hsl(141, 53%, 53%);
$turquoise: hsl(171, 100%, 41%);
$cyan: hsl(204, 71%, 53%);
$blue: hsl(217, 71%, 53%);
$purple: hsl(271, 100%, 71%);
$red: hsl(348, 86%, 61%);