@import "./colors.scss";

$scheme-main: $dark;
$scheme-main-bis: $dark-bis;
$scheme-main-ter: $dark-ter;
$scheme-invert: $light;
$scheme-invert-bis: $light-bis;
$scheme-invert-ter: $light-ter;

$background: $dark-ter;

$border: $mid-darker;
$border-hover: $mid-dark;
$border-light: $mid-darker;
$border-light-hover: $mid-dark;

// Text colors
$text: $mid-light;
$text-invert: $mid-darker;
$text-light: $mid;
$text-strong: $light;

// Link colors
$link: $mid-lighter;
$link-hover: $light;
$link-hover-border: $mid-dark;

$link-focus: $light;
$link-focus-border: $blue;

$link-active: $light;
$link-active-border: $mid-light;

// Button colors
$button: $mid-lighter;
$button-hover: $light;
$button-hover-border: $mid-dark;

$button-focus: $light;
$button-focus-border: $blue;

$button-active: $light;
$button-active-border: $mid-light;

// progress bars
$progress-bar-background-color: $light;

.bd-navbar {
  background-color: $scheme-main-bis;

  &.has-shadow {
    box-shadow: none;
  }
}

body {
  background: $dark-ter;
}