
.toast {
  text-align: center;
  position: fixed;
  z-index: 1;
  bottom: 8vh;
  width:100%;
}

.toast.show {
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

.toast.hide {
  -webkit-animation: fadeout 0.5s 2.5s;
  animation: fadeout 2s;
}

@-webkit-keyframes fadein {
  from {bottom: -8vh; opacity: 0;} 
  to {bottom: 8vh; opacity: 1;}
}

@keyframes fadein {
  from {bottom: -8vh; opacity: 0;}
  to {bottom: 8vh; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 8vh; opacity: 1;} 
  to {bottom: -8vh; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 8vh; opacity: 1;}
  to {bottom: -8vh; opacity: 0;}
}