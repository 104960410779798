$wip_colour1: hsl(20, 20%, 26%);
$wip_colour2:  hsl(20, 20%, 14%) ;
.wip {
  background: -webkit-linear-gradient(
    45deg,
    $wip_colour1 25%,
    $wip_colour2 25%,
    $wip_colour2 50%,
    $wip_colour1 50%,
    $wip_colour1 75%,
    $wip_colour2 75%
  );
  background-size: 40px 40px;
}
